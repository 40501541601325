@font-face {
  font-family: 'BasicAbuDhabi-Regular';
  src: url('./fonts/BasicAbuDhabi/BasicAbuDhabi-Regular.woff2') format('woff2'),
    url('./fonts/BasicAbuDhabi/BasicAbuDhabi-Regular.woff') format('woff'),
    url('./fonts/BasicAbuDhabi/BasicAbuDhabi-Regular.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'BasicAbuDhabi-Medium';
  src: url('./fonts/BasicAbuDhabi/BasicAbuDhabi-Medium.woff2') format('woff2'),
    url('./fonts/BasicAbuDhabi/BasicAbuDhabi-Medium.woff') format('woff'),
    url('./fonts/BasicAbuDhabi/BasicAbuDhabi-Medium.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'BasicAbuDhabi-Black';
  src: url('./fonts/BasicAbuDhabi/BasicAbuDhabi-Bold.woff2') format('woff2'),
    url('./fonts/BasicAbuDhabi/BasicAbuDhabi-Bold.woff') format('woff'),
    url('./fonts/BasicAbuDhabi/BasicAbuDhabi-Bold.ttf') format('truetype');                     
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'BasicAbuDhabi-Light';
  src: url('./fonts/BasicAbuDhabi/BasicAbuDhabi-Light.woff2') format('woff2'),
    url('./fonts/BasicAbuDhabi/BasicAbuDhabi-Light.woff') format('woff'),
    url('./fonts/BasicAbuDhabi/BasicAbuDhabi-Light.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'BasicAbuDhabi-Bold';
  src: url('./fonts/BasicAbuDhabi/BasicAbuDhabi-Bold.woff2') format('woff2'),
    url('./fonts/BasicAbuDhabi/BasicAbuDhabi-Bold.woff') format('woff'),
    url('./fonts/BasicAbuDhabi/BasicAbuDhabi-Bold.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Tinos-Regular';
  src: url('./fonts/Tinos/Tinos-Regular.woff2') format('woff2'),
    url('./fonts/Tinos/Tinos-Regular.woff') format('woff'),
    url('./fonts/Tinos/Tinos-Regular.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Tinos-Bold';
  src: url('./fonts/Tinos/Tinos-Bold.woff2') format('woff2'),
    url('./fonts/Tinos/Tinos-Bold.woff') format('woff'),
    url('./fonts/Tinos/Tinos-Bold.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'BasisGrotesqueArabic-Regular';
  src: url('./fonts/BasisGrotesqueArabic/BasisGrotesqueArabic-Regular-Pro.woff2')
      format('woff2'),
    url('./fonts/BasisGrotesqueArabic/BasisGrotesqueArabic-Regular-Pro.woff') format('woff'),
    url('./fonts/BasisGrotesqueArabic/BasisGrotesqueArabic-Regular-Pro.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'BasisGrotesqueArabic-Bold';
  src: url('./fonts/BasisGrotesqueArabic/BasisGrotesqueArabic-Bold-Pro.woff2')
      format('woff2'),
    url('./fonts/BasisGrotesqueArabic/BasisGrotesqueArabic-Bold-Pro.woff') format('woff'),
    url('./fonts/BasisGrotesqueArabic/BasisGrotesqueArabic-Bold-Pro.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'BasisGrotesqueArabic-Light';
  src: url('./fonts/BasisGrotesqueArabic/BasisGrotesqueArabic-Light-Pro.woff2')
      format('woff2'),
    url('./fonts/BasisGrotesqueArabic/BasisGrotesqueArabic-Light-Pro.woff') format('woff'),
    url('./fonts/BasisGrotesqueArabic/BasisGrotesqueArabic-Light-Pro.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'BasisGrotesqueArabic-Black';
  src: url('./fonts/BasisGrotesqueArabic/BasisGrotesqueArabic-Medium-Pro.woff2')
      format('woff2'),
    url('./fonts/BasisGrotesqueArabic/BasisGrotesqueArabic-Medium-Pro.woff') format('woff'),
    url('./fonts/BasisGrotesqueArabic/BasisGrotesqueArabic-Medium-Pro.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NotoKufiArabic';
  src: url('./fonts/NotoKufiArabic/NotoKufiArabic-Regular.woff2')
      format('woff2'),
    url('./fonts/NotoKufiArabic/NotoKufiArabic-Regular.woff') format('woff'),
    url('./fonts/NotoKufiArabic/NotoKufiArabic-Regular.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NotoKufiArabic-Bold';
  src: url('./fonts/NotoKufiArabic/NotoKufiArabic-Bold.woff2') format('woff2'),
    url('./fonts/NotoKufiArabic/NotoKufiArabic-Bold.woff') format('woff'),
    url('./fonts/NotoKufiArabic/NotoKufiArabic-Bold.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NotoSansSc-Light';
  src: url('./fonts/NotoSansSc/NotoSansSC-Light.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NotoSansSc-Regular';
  src: url('./fonts/NotoSansSc/NotoSansSC-Regular.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NotoSansSc-Bold';
  src: url('./fonts/NotoSansSc/NotoSansSC-Bold.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NotoSansSc-Black';
  src: url('./fonts/NotoSansSc/NotoSansSC-Black.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NotoSerifSc-Black';
  src: url('./fonts/NotoSerifSc/NotoSerifSC-Black.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NotoSerifSc-SemiBold';
  src: url('./fonts/NotoSerifSc/NotoSerifSC-SemiBold.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NotoSerifSc-Bold';
  src: url('./fonts/NotoSerifSc/NotoSerifSC-Bold.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NotoSerifSc-Regular';
  src: url('./fonts/NotoSerifSc/NotoSerifSC-Regular.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Heebo-Light';
  src: url('./fonts/Heebo/Heebo-Light.woff2') format('woff2'),
    url('./fonts/Heebo/Heebo-Light.woff') format('woff'),
    url('./fonts/Heebo/Heebo-Light.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Heebo-Regular';
  src: url('./fonts/Heebo/Heebo-Regular.woff2') format('woff2'),
    url('./fonts/Heebo/Heebo-Regular.woff') format('woff'),
    url('./fonts/Heebo/Heebo-Regular.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Heebo-Bold';
  src: url('./fonts/Heebo/Heebo-Bold.woff2') format('woff2'),
    url('./fonts/Heebo/Heebo-Bold.woff') format('woff'),
    url('./fonts/Heebo/Heebo-Bold.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'BasisGrotesque-Regular';
  src: url('./fonts/BasisGrotesque/BasisGrotesque-Regular-Pro.woff2')
      format('woff2'),
    url('./fonts/BasisGrotesque/BasisGrotesque-Regular-Pro.woff') format('woff'),
    url('./fonts/BasisGrotesque/BasisGrotesque-Regular-Pro.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'BasisGrotesque-Medium';
  src: url('./fonts/BasisGrotesque/BasisGrotesque-Medium-Pro.woff2')
      format('woff2'),
    url('./fonts/BasisGrotesque/BasisGrotesque-Medium-Pro.woff') format('woff'),
    url('./fonts/BasisGrotesque/BasisGrotesque-Medium-Pro.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'BasisGrotesque-Light';
  src: url('./fonts/BasisGrotesque/BasisGrotesque-Light-Pro.woff2')
      format('woff2'),
    url('./fonts/BasisGrotesque/BasisGrotesque-Light-Pro.woff') format('woff'),
    url('./fonts/BasisGrotesque/BasisGrotesque-Light-Pro.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'BasisGrotesque-Bold';
  src: url('./fonts/BasisGrotesque/BasisGrotesque-Bold-Pro.woff2')
      format('woff2'),
    url('./fonts/BasisGrotesque/BasisGrotesque-Bold-Pro.woff') format('woff'),
    url('./fonts/BasisGrotesque/BasisGrotesque-Bold-Pro.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

html {
  font-family: BasicAbuDhabi-Regular, Arial, sans-serif;
}

html:lang(ar) {
  font-family: NotoKufiArabic, Arial, sans-serif;
}

html:lang(he) {
  font-family: Heebo-Regular, Arial, sans-serif;
}

html:lang(zh),
html:lang(ru) {
  font-family: NotoSansSc-Regular, Arial, sans-serif;
}

body {
  margin: 0;
  background-color: #fff;
  overflow-x: hidden;
  position: relative;
}
section {
  position: relative;
}

*,
::after,
::before {
  box-sizing: border-box;
}

/* added global svg dimension*/
svg {
  max-width: 96px;
  max-height: 96px;
}

@keyframes slideInFromLeft {
  0% {
    left: 0;
    opacity: 0;
  }

  100% {
    left: 5.5rem;
    opacity: 1;
  }
}

@keyframes slideInFromBottom {
  0% {
    bottom: -7rem;
    opacity: 0;
  }

  100% {
    bottom: -3.5rem;
    opacity: 1;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1439px) {
  html {
    font-size: 80%;
  }
}

.react-datepicker__navigation{
  width: 10px !important;
  height: 10px !important;
}

.react-datepicker__navigation-icon--previous::before{
  display: none !important;
}
.react-datepicker__navigation-icon--next::before{
  display: none !important;
}